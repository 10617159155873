var hamburger = $('.hamburger');
var offcanvas = $('#offcanvas-menu');
var header = $('.header');

hamburger.on('click', () => {
  hamburger.toggleClass('is-active');
  header.toggleClass('mobile-nav-open');
  offcanvas.toggleClass('show');
});

offcanvas.on('hidden.bs.offcanvas', () => {
  hamburger.removeClass('is-active');
  header.removeClass('mobile-nav-open');
});
